<template>
  <template v-if="tabs.length > 0">
    <div class="user-info" v-if="userName">{{userName}}({{userPhone}})的消费列表</div>
    <div class="screen-tongji">
      <div>
        <p>{{totalElements || 0}}</p>
        <span>订单总数</span>
      </div>
      <div>
        <p>{{totalAmount || 0}}</p>
        <span>订单总额</span>
      </div>
    </div>
    <van-tabs v-model:active="active" :before-change="beforeChange">
      <van-tab v-for="(tab, index) in tabs" :title="tab.v" :key="index">
        <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="getData"
        >
          <div v-for="item in items" :key="item.orderId" style="margin:7px 0 0 0;">
            <van-cell :title="item.store.name" :value="item.statusText" icon="shop-o" />
            <van-card
              :num="item.productQuantity"
              :price="item.productPrice"
              :desc="item.productSkuText"
              :title="item.productName"
              :thumb="item.productImage"
            >
              <template #tags v-if="item.bookingDate">
                <van-tag plain type="danger">{{ item.bookingDate }}</van-tag>
              </template>
            </van-card>
            <div style="background:#fff;line-height:1.6;padding:7px;">
              <p>
                <small>
                  下单时间: {{ item.createTime }}
                </small>
              </p>
              <!-- <template v-if="item.groupon !== null">
                <van-tag plain type="primary">团购订单</van-tag>&nbsp;
                <van-tag plain type="primary">{{ convertGrouponStatus(item.groupon.status) }}</van-tag>&nbsp;
                <van-tag plain type="primary">
                  {{ item.groupon.minParticipants }}人团
                  <template v-if="item.groupon.minParticipants > item.groupon.currentParticipants">
                    ，还差{{ item.groupon.minParticipants - item.groupon.currentParticipants }}人
                  </template>
                </van-tag>
              </template> -->
            </div>
            <div style="background:#fff;padding:15px;text-align:right;">
              <van-button size="small" :to="{ name: 'OrderDetail', query: { orderId: item.orderId } }">订单详情</van-button>
            </div>
          </div>
        </van-list>
      </van-tab>
    </van-tabs>
  </template>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import { listOrderStatus, convertGrouponStatusText } from '@/util/util'

export default {
  setup () {
    const post = inject('post')
    const useRoute = inject('useRoute')
    const Cookies = inject('Cookies')
    const state = reactive({
      page: 1,
      finished: false,
      loading: false,
      items: [],
      active: useRoute.query.tab ? parseInt(useRoute.query.tab) : 0,
      tabs: [],
      buyerId: Cookies.get('buyerId'),
      storeId: Cookies.get('storeId'),
      userPhone: useRoute.query.userPhone ? useRoute.query.userPhone : null,
      userName: useRoute.query.userName ? useRoute.query.userName : null,
      totalElements: 0,
      totalAmount: 0
    })
    const convertGrouponStatus = (value) => {
      return convertGrouponStatusText({ status: value })
    }
    const init = () => {
      state.tabs = listOrderStatus()
      if (useRoute.query.buyerId) {
        state.buyerId = useRoute.query.buyerId
      }
      // getData()
    }
    const getData = () => {
      post('/order.list', {
        pageNum: state.page,
        storeId: state.storeId,
        buyerId: state.buyerId,
        status: state.tabs[state.active].k,
        bizScope: 'GENERAL'
      }).then(res => {
        state.items = [...state.items, ...res.data.content]
        state.loading = false
        state.finished = res.data.last
        state.totalElements = res.data.totalElements
        state.totalAmount = res.data.totalAmount
        state.page++
      })
    }
    const reset = () => {
      state.items = []
      state.page = 1
      state.finished = false
      state.loading = false
    }
    const beforeChange = (index) => {
      state.active = index
      reset()
      return true
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      getData,
      beforeChange,
      convertGrouponStatus
    }
  }
}
</script>

<style scoped>
.user-info{
  line-height: 35px;
  background-color: #fff;
  padding-left: 15px;
  font-weight: 500;
}
.screen-tongji{
  display: flex;
  background-color: #fff;
}
.screen-tongji div{
  text-align: center;
  width: 50%;
  font-size: 13px;
}
.screen-tongji div p{
  font-size: 16px;
  margin-bottom: 3px;
  font-weight: 500;
}
</style>
